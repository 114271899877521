import 'html5-boilerplate/dist/js/vendor/modernizr-3.11.2.min.js';
import 'html5-boilerplate/dist/js/plugins.js';
import lazySizes from 'lazysizes';
// import 'lazysizes/plugins/attrchange/ls.attrchange';

import ReactDOM from 'react-dom/client';
import { App } from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);
