[
  {
    "numero": 29,
    "titulo": "Perspetiva Duma Década: Avaliação Do Diagnóstico Laboratorial De Sífilis No CHUC",
    "autor": "Sabina Brito et al.",
    "ficheiro": "P - 29.jpg"
  },
  {
    "numero": 54,
    "titulo": "Infertilidade Masculina: Formas De Diagnóstico",
    "autor": "Aline Correia et al.",
    "ficheiro": "P - 54.jpg"
  },
  {
    "numero": 55,
    "titulo": "Hábitos Alimentares E Sucesso Académico Em Estudantes Universitários",
    "autor": "Adriana Pires et al.",
    "ficheiro": "P - 55.jpg"
  },
  {
    "numero": 57,
    "titulo": "Alteração Da Imunidade Em Crianças Após A Pandemia: Evidência Dos Valores Séricos De Iga",
    "autor": "Luis Martinho et al.",
    "ficheiro": "P - 57.jpg"
  },
  {
    "numero": 66,
    "titulo": "Pseudohiponatremia: a propósito de um caso clínico",
    "autor": "Lília Cardoso et al.",
    "ficheiro": "P - 66.jpg"
  },
  {
    "numero": 15,
    "titulo": "Tendência Da Infecção Pelo Vírus Da Imunodeficiência Humana Em Dadores De Sangue Em Portugal (2015-2022)",
    "autor": "Isaías Pedro, Pedro Mendonça, Maria Antónia Escoval, João Almeida Santos",
    "ficheiro": "P - 15.jpg"
  },
  {
    "numero": 43,
    "titulo": "Avaliação Externa da Qualidade na determinação do estudo Imunohematológico no Instituto Português do  Sangue e da Transplantação - Delegação do Algarve",
    "autor": "Paula Rasteiro et al.",
    "ficheiro": "P - 43.jpg"
  },
  {
    "numero": 32,
    "titulo": "Impacto Da Infeção Por EBV Em Doentes Diagnosticados Com LLC",
    "autor": "Raquel Costa, Rodolfo Serra",
    "ficheiro": "P - 32.jpg"
  }
]
