[
  {
    "numero": 2,
    "titulo": "Parasitose Intestinal Por Hymenolepis Nana – Caso Clínico",
    "autor": "Cláudia Freitas",
    "ficheiro": "P - 2.jpg"
  },
  {
    "numero": 3,
    "titulo": "Parasitose Intestinal – Um Diagnóstico Laboratorial Cada Vez Mais Raro",
    "autor": "Cláudia Freitas",
    "ficheiro": "P - 3.jpg"
  },
  {
    "numero": 5,
    "titulo": "Transferência De Tecidos: Uma Técnica De Apoio",
    "autor": "Maria Manuela Katzenberger Baptista Novo",
    "ficheiro": "P - 5.jpg"
  },
  {
    "numero": 6,
    "titulo": "Histogel Vs Cellient: A Comparative Study",
    "autor": "Rita Fagulha; Rúben Roque; Carla Pinheiro; Vanessa Tavares; Saudade André",
    "ficheiro": "P - 6.jpg"
  },
  {
    "numero": 9,
    "titulo": "Comparação Dos Subtipos Moleculares De Cancro Da Mama Em Dois Grupos Etários Diferentes",
    "autor": "Duarte, B.; Antunes, P; Coelho, T; Gonçalves, M.; Vicente, S.; Possante, A.R.; Gago-Rodrigues, I.; Plácido, R.; Furtado, J.",
    "ficheiro": "P - 9.jpg"
  },
  {
    "numero": 10,
    "titulo": "Impact of CDK4/6 inhibitors-induced senescence in triple-negative breast cancer epithelial-to-mesenchymal transition",
    "autor": "Érica B. Martins, Joana Martins, Luís Costa, Sandra Casimiro",
    "ficheiro": "P - 10.jpg"
  },
  {
    "numero": 12,
    "titulo": "Caracterização Dos Monócitos E A Sua Relação Com O MDW Na Covid-19",
    "autor": "Cristiana Oliveira et al.",
    "ficheiro": "P - 12.jpg"
  },
  {
    "numero": 15,
    "titulo": "Tendência Da Infecção Pelo Vírus Da Imunodeficiência Humana Em Dadores De Sangue Em Portugal (2015-2022)",
    "autor": "Isaías Pedro, Pedro Mendonça, Maria Antónia Escoval, João Almeida Santos",
    "ficheiro": "P - 15.jpg"
  },
  {
    "numero": 18,
    "titulo": "Líquidos biológicos – papel dos marcadores tumorais no contexto citológico e perfil imunofenotípico",
    "autor": "Catarina Lopes et al.",
    "ficheiro": "P -18.jpg"
  },
  {
    "numero": 20,
    "titulo": "Comparação no armazenamento de plaquetas em sacos de plástico butiril trihexil citrato (BTHC) e 2-dietil-hexil ftalato (DEHP): a diferença na qualidade e nos parâmetros metabólicos",
    "autor": "Susana Ponte et al.",
    "ficheiro": "P - 20.jpg"
  },
  {
    "numero": 21,
    "titulo": "Duas Décadas De Dádivas De Sangue Na Uls Santo António",
    "autor": "Ferreira, Carla et al.",
    "ficheiro": "P - 21.jpg"
  },
  {
    "numero": 22,
    "titulo": "Fenótipo A Fraco – Subgrupo raro: Ax Caso Clínico",
    "autor": "Andreia Reis et al.",
    "ficheiro": "P - 22.jpg"
  },
  {
    "numero": 23,
    "titulo": "Suporte Transfusional No Hospital De Dia Polivalente Da Uls Santo António (Uls Sa)",
    "autor": "Ferreira, Carla et al.",
    "ficheiro": "P - 23.jpg"
  },
  {
    "numero": 24,
    "titulo": "Avaliação da perda do fator VIII no processo de produção de plasma fresco congelado destinado a fracionamento industrial",
    "autor": "Susana Ponte et al.",
    "ficheiro": "P - 24.jpg"
  },
  {
    "numero": 26,
    "titulo": "The Importance Of The IDH Molecular Test In Glioma Diagnosis",
    "autor": "Ana Sofia Lopes, Ana Santos, Catarina Pardelha, Martinha Chorão",
    "ficheiro": "P - 26.jpg"
  },
  {
    "numero": 29,
    "titulo": "Perspetiva Duma Década: Avaliação Do Diagnóstico Laboratorial De Sífilis No CHUC",
    "autor": "Sabina Brito et al.",
    "ficheiro": "P - 29.jpg"
  },
  {
    "numero": 30,
    "titulo": "Avaliação das unidades de Sangue Total (ST) com volume não conforme processadas na Delegação do Algarve do Instituto Português do Sangue e da Transplantação, IP (LLA/IPST)",
    "autor": "Isa Oliveira, Fábia Encarnação, Vera Maria, Paula Rasteiro, Eugénia Vasconcelos",
    "ficheiro": "P - 30.jpg"
  },
  {
    "numero": 32,
    "titulo": "Impacto Da Infeção Por EBV Em Doentes Diagnosticados Com LLC",
    "autor": "Raquel Costa, Rodolfo Serra",
    "ficheiro": "P - 32.jpg"
  },
  {
    "numero": 33,
    "titulo": "Análise do Volume dos Concentrados Eritrocitários produzidos no IPST – Delegação do Algarve (LLA/IPST) e o valor da hemoglobina (Hgb) da pessoa dadora",
    "autor": "Mónica Baptista, Marta Saias et al",
    "ficheiro": "P - 33.jpg"
  },
  {
    "numero": 34,
    "titulo": "Caso Clínico - Valor Preditivo Do Cariótipo",
    "autor": "Rodolfo Serra, Raquel Costa, Andreia Sousa, Pedro Facão, Nélia Jerónimo",
    "ficheiro": "P - 34.jpg"
  },
  {
    "numero": 35,
    "titulo": "Teste De Antiglobulina Direto Positivo Em Microcoluna De Gel- Resultados Em Doentes",
    "autor": "Paula Fernandes, Graça Almeida",
    "ficheiro": "P - 35.jpg"
  },
  {
    "numero": 37,
    "titulo": "Prevalência da Grupagem Sanguínea nas Dádivas do Algarve",
    "autor": "Vera Maria, Marta Saias et al.",
    "ficheiro": "P - 37.jpg"
  },
  {
    "numero": 41,
    "titulo": "Avaliação De Desempenho De Um Hemoglobinómetro Point Of Care Em Dadores De Sangue Total",
    "autor": "Anabela Martins et al.",
    "ficheiro": "P - 41.jpg"
  },
  {
    "numero": 42,
    "titulo": "Coagulopatia No Covid-19",
    "autor": "Marques, B.",
    "ficheiro": "P - 42.jpg"
  },
  {
    "numero": 43,
    "titulo": "Avaliação Externa da Qualidade na determinação do estudo Imunohematológico no Instituto Português do  Sangue e da Transplantação - Delegação do Algarve",
    "autor": "Paula Rasteiro et al.",
    "ficheiro": "P - 43.jpg"
  },
  {
    "numero": 44,
    "titulo": "Avaliação Do Status Mutacional Dos Genes Mmr No Cancro Do Endométrio Na População Que Recorre Ao Centro Hospitalar Universitário Do Algarve",
    "autor": "Coelho, J. et al.",
    "ficheiro": "P - 44.jpg"
  },
  {
    "numero": 45,
    "titulo": "Screening do agente parasitário Plasmodium spp. em pessoas dadoras da  região do Algarve e suas implicações",
    "autor": "Fábia Encarnação, Paula Rasteiro",
    "ficheiro": "P - 45.jpg"
  },
  {
    "numero": 47,
    "titulo": "Finefix® Fixation In Histopathological Samples With Macroscopic, Histochemistry And Immunohistochemistry Assessment: A Systematic Literature Review",
    "autor": "Natacha Monteiro et al.",
    "ficheiro": "P - 47.jpg"
  },
  {
    "numero": 48,
    "titulo": "Formalin-Free Histological Fixation And Its Impact On Organs, Cells, Proteins And Nucleic Acids - A Systematic Literasture Review",
    "autor": "Inês Lino et al.",
    "ficheiro": "P - 48.jpg"
  },
  {
    "numero": 49,
    "titulo": "Natural Oils As Substitutes For Xylene In The Clearing Step Of Histological Tissue Processing: A Systematic Literature Review",
    "autor": "Cátia Pinto et al.",
    "ficheiro": "P - 49.jpg"
  },
  {
    "numero": 50,
    "titulo": "The Role of Open Education In Improving The Accuracy Of Death Certification Practices Among Biomedical Professionals",
    "autor": "Amadeu Borges-Ferro et al.",
    "ficheiro": "P - 50.jpg"
  },
  {
    "numero": 54,
    "titulo": "Infertilidade Masculina: Formas De Diagnóstico",
    "autor": "Aline Correia et al.",
    "ficheiro": "P - 54.jpg"
  },
  {
    "numero": 55,
    "titulo": "Hábitos Alimentares E Sucesso Académico Em Estudantes Universitários",
    "autor": "Adriana Pires et al.",
    "ficheiro": "P - 55.jpg"
  },
  {
    "numero": 56,
    "titulo": "Incidência E Mortalidade Por Cancro Do Estômago Em Países Onde Predomina A Dieta Mediterrânica",
    "autor": "Beatriz Correia et al.",
    "ficheiro": "P - 56.jpg"
  },
  {
    "numero": 57,
    "titulo": "Alteração Da Imunidade Em Crianças Após A Pandemia: Evidência Dos Valores Séricos De Iga",
    "autor": "Luis Martinho et al.",
    "ficheiro": "P - 57.jpg"
  },
  {
    "numero": 60,
    "titulo": "A 3d Tumor-On-Chip Platform To Study Intravasation",
    "autor": "Diogo Coutinho, Carolina Morais, et al.",
    "ficheiro": "P - 60.jpg"
  },
  {
    "numero": 61,
    "titulo": "O Diagnóstico Das Hemoglobinopatias Mais Prevalentes Em Portugal",
    "autor": "Liliana Luís, Sandrine Mendes e Ricardo Castro",
    "ficheiro": "P - 61.jpg"
  },
  {
    "numero": 62,
    "titulo": "Uso De Filtros No Processamento De Citologia De Base Líquida: Importante Discriminar?",
    "autor": "Tiago Monteiro, Sílvia Fernandes, Regina Silva",
    "ficheiro": "P - 62.jpg"
  },
  {
    "numero": 65,
    "titulo": "Computational Pathology: What's New",
    "autor": "Daniel Coelho et al.",
    "ficheiro": "P - 65.jpg"
  },
  {
    "numero": 66,
    "titulo": "Pseudohiponatremia: a propósito de um caso clínico",
    "autor": "Lília Cardoso et al.",
    "ficheiro": "P - 66.jpg"
  },
  {
    "numero": 67,
    "titulo": "Nódulo Hiperplásico Adenomatóide Da Tiroide: A Propósito De Um Caso Clínico",
    "autor": "Neuza Costa et al.",
    "ficheiro": "P - 67.jpg"
  },
  {
    "numero": 69,
    "titulo": "Controlo Da Qualidade Na Fase Pré- Analítica: Interferência Da Proporção De Sangue-Anticoagulante Da Análise Do Tp E Ttpa",
    "autor": "Silva, A. et al.",
    "ficheiro": "P - 69.jpg"
  },
  {
    "numero": 70,
    "titulo": "Determinação Da Incerteza Associada À Fase Pré-Analítica: Estudos De Hemostase",
    "autor": "Rui Plácido Raposo et al.",
    "ficheiro": "P - 70.jpg"
  },
  {
    "numero": 71,
    "titulo": "Análise Comparativa de duas metodologias de PCR para a deteção e quantificação do EBV",
    "autor": "Raquel Costa et al.",
    "ficheiro": "P - 71.jpg"
  },
  {
    "numero": 72,
    "titulo": "Rampa: 1 Ano De Experiencia No Processamento Histológico",
    "autor": "Paula Fonseca et al.",
    "ficheiro": "P - 72.jpg"
  },
  {
    "numero": 73,
    "titulo": "Caraterização das células T em biópsias de gânglios com suspeita de doença linfoproliferativa crónica de células B, para posterior avaliação do seu valor prognóstico.",
    "autor": "Isabel Silva et al.",
    "ficheiro": "P - 73.jpg"
  },
  {
    "numero": 74,
    "titulo": "Doença Pneumocócica Invasiva – Relato De Um Caso Clínico",
    "autor": "Rodrigues, A. et al.",
    "ficheiro": "P - 74.jpg"
  },
  {
    "numero": 75,
    "titulo": "Quantificação Celular No Líquido Cefalorraquidiano Utilizando O Alinity Hq: Será A Automatização Uma Possibilidade?",
    "autor": "Frederico Bragança, Rui Barreira e Edna Ribeiro",
    "ficheiro": "P - 75.jpg"
  },
  {
    "numero": 76,
    "titulo": "Caso Clínico: Peça De Penectomia Restante Por Recidiva De Carcinoma Epidermóide.",
    "autor": "Almeida, R.; Barata, C.; Fernandes, M.; Tavares, T.",
    "ficheiro": "P - 76.jpg"
  }
]
