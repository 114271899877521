import { useEffect, useContext } from 'react';

import { styled } from '@mui/system';

import {
  Box,
  Fab
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Glide from '@glidejs/glide';

import PaperSlide from './components/PaperSlide';

import { AppContext } from './App';

const FullHeightDiv = styled('div')({
  height: '100%'
});

const FullHeightUl = styled('ul')({
  height: '100%'
});

const AutoOverflowLi = styled('li')({
  overflow: 'auto'
});

export default function GalleryTab(props) {

  const context = useContext(AppContext);
  let glide;

  useEffect(() => {
    glide = new Glide('.glide', {
      type: 'carousel'
    }).mount();

    if(context.posterIndex)
      glide.update({
        startAt: context.posterIndex
      });
  });

  return <>
    <FullHeightDiv className="glide">
      <FullHeightDiv className="glide__track" data-glide-el="track">
        <FullHeightUl className="glide__slides">
          {props.postersData.map(posterData => (
            <AutoOverflowLi className="glide__slide" key={posterData.numero}>
              <PaperSlide posterData={posterData} />
            </AutoOverflowLi>
          ))}
        </FullHeightUl>
      </FullHeightDiv>

      <Box className="glide__arrows" data-glide-el="controls" sx={{
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: 1,
        paddingRight: 1,
        pointerEvents: 'none'
      }}>
        <Fab color="primary" className="glide__arrow glide__arrow--left" data-glide-dir="<" sx={{ pointerEvents: 'initial' }}><ArrowBackIcon /></Fab>
        <Fab color="primary" className="glide__arrow glide__arrow--right" data-glide-dir=">" sx={{ pointerEvents: 'initial' }}><ArrowForwardIcon /></Fab>
      </Box>
    </FullHeightDiv>
  </>;
}
