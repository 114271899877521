[
  {
    "numero": 69,
    "titulo": "Controlo Da Qualidade Na Fase Pré- Analítica: Interferência Da Proporção De Sangue-Anticoagulante Da Análise Do Tp E Ttpa",
    "autor": "Silva, A. et al.",
    "ficheiro": "P - 69.jpg"
  },
  {
    "numero": 70,
    "titulo": "Determinação Da Incerteza Associada À Fase Pré-Analítica: Estudos De Hemostase",
    "autor": "Rui Plácido Raposo et al.",
    "ficheiro": "P - 70.jpg"
  },
  {
    "numero": 41,
    "titulo": "Avaliação De Desempenho De Um Hemoglobinómetro Point Of Care Em Dadores De Sangue Total",
    "autor": "Anabela Martins et al.",
    "ficheiro": "P - 41.jpg"
  },
  {
    "numero": 30,
    "titulo": "Avaliação das unidades de Sangue Total (ST) com volume não conforme processadas na Delegação do Algarve do Instituto Português do Sangue e da Transplantação, IP (LLA/IPST)",
    "autor": "Isa Oliveira, Fábia Encarnação, Vera Maria, Paula Rasteiro, Eugénia Vasconcelos",
    "ficheiro": "P - 30.jpg"
  },
  {
    "numero": 20,
    "titulo": "Comparação no armazenamento de plaquetas em sacos de plástico butiril trihexil citrato (BTHC) e 2-dietil-hexil ftalato (DEHP): a diferença na qualidade e nos parâmetros metabólicos",
    "autor": "Susana Ponte et al.",
    "ficheiro": "P - 20.jpg"
  },
  {
    "numero": 12,
    "titulo": "Caracterização Dos Monócitos E A Sua Relação Com O MDW Na Covid-19",
    "autor": "Cristiana Oliveira et al.",
    "ficheiro": "P - 12.jpg"
  },
  {
    "numero": 37,
    "titulo": "Prevalência da Grupagem Sanguínea nas Dádivas do Algarve",
    "autor": "Vera Maria, Marta Saias et al.",
    "ficheiro": "P - 37.jpg"
  },
  {
    "numero": 18,
    "titulo": "Líquidos biológicos – papel dos marcadores tumorais no contexto citológico e perfil imunofenotípico",
    "autor": "Catarina Lopes et al.",
    "ficheiro": "P -18.jpg"
  }
]
