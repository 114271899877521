import { useLayoutEffect, useRef } from 'react';

import { List } from '@mui/material';

import PaperListItem from './components/PaperListItem'

export default function ListTab(props) {

  const listRef = useRef();

  useLayoutEffect(() => {
    const onScroll = (e) => {
      if(e.target)
        localStorage.setItem('listScroll', e.target.scrollTop);
    };

    if(listRef.current) {
      listRef.current.addEventListener('scroll', onScroll);
      listRef.current.scroll({ top: parseInt(localStorage.getItem('listScroll')) || 0 });
    }

    return () => {
      if(listRef.current)
        listRef.current.removeEventListener('scroll', onScroll);
    }
  });

  return <>
    { props.postersData && (
      <List disablePadding ref={listRef} sx={{ height: '100%', overflow: 'auto' }}>
        {props.postersData.map( (posterData, index) => <PaperListItem key={posterData.numero} index={index} posterData={posterData} />)}
      </List>
    )}

  </>;
}
