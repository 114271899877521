import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText
} from '@mui/material';

import { useContext } from 'react';

import { AppContext } from '../App';

export default function PaperListItem(props) {
  const context = useContext(AppContext);

  return <>
    <ListItem disablePadding>
      <ListItemButton onClick={() => {
        context.setContext({
          tab:1,
          posterIndex: props.index
        });
      }}>
        <ListItemText
          primary={
            <>
              {props.posterData.numero}. {props.posterData.titulo}
            </>
          }
          secondary={
            <>
              {props.posterData.autor}
            </>
          }
        />
      </ListItemButton>
    </ListItem>
    <Divider />
  </>;
}
