[
  {
    "numero": 22,
    "titulo": "Fenótipo A Fraco – Subgrupo raro: Ax Caso Clínico",
    "autor": "Andreia Reis et al.",
    "ficheiro": "P - 22.jpg"
  },
  {
    "numero": 23,
    "titulo": "Suporte Transfusional No Hospital De Dia Polivalente Da Uls Santo António (Uls Sa)",
    "autor": "Ferreira, Carla et al.",
    "ficheiro": "P - 23.jpg"
  },
  {
    "numero": 24,
    "titulo": "Avaliação da perda do fator VIII no processo de produção de plasma fresco congelado destinado a fracionamento industrial",
    "autor": "Susana Ponte et al.",
    "ficheiro": "P - 24.jpg"
  },
  {
    "numero": 21,
    "titulo": "Duas Décadas De Dádivas De Sangue Na Uls Santo António",
    "autor": "Ferreira, Carla et al.",
    "ficheiro": "P - 21.jpg"
  },
  {
    "numero": 75,
    "titulo": "Quantificação Celular No Líquido Cefalorraquidiano Utilizando O Alinity Hq: Será A Automatização Uma Possibilidade?",
    "autor": "Frederico Bragança, Rui Barreira e Edna Ribeiro",
    "ficheiro": "P - 75.jpg"
  },
  {
    "numero": 35,
    "titulo": "Teste De Antiglobulina Direto Positivo Em Microcoluna De Gel- Resultados Em Doentes",
    "autor": "Paula Fernandes, Graça Almeida",
    "ficheiro": "P - 35.jpg"
  },
  {
    "numero": 73,
    "titulo": "Caraterização das células T em biópsias de gânglios com suspeita de doença linfoproliferativa crónica de células B, para posterior avaliação do seu valor prognóstico.",
    "autor": "Isabel Silva et al.",
    "ficheiro": "P - 73.jpg"
  },
  {
    "numero": 33,
    "titulo": "Análise do Volume dos Concentrados Eritrocitários produzidos no IPST – Delegação do Algarve (LLA/IPST) e o valor da hemoglobina (Hgb) da pessoa dadora",
    "autor": "Mónica Baptista, Marta Saias et al",
    "ficheiro": "P - 33.jpg"
  }
]
