[
  {
    "numero": 10,
    "titulo": "Impact of CDK4/6 inhibitors-induced senescence in triple-negative breast cancer epithelial-to-mesenchymal transition",
    "autor": "Érica B. Martins, Joana Martins, Luís Costa, Sandra Casimiro",
    "ficheiro": "P - 10.jpg"
  },
  {
    "numero": 49,
    "titulo": "Natural Oils As Substitutes For Xylene In The Clearing Step Of Histological Tissue Processing: A Systematic Literature Review",
    "autor": "Cátia Pinto et al.",
    "ficheiro": "P - 49.jpg"
  },
  {
    "numero": 5,
    "titulo": "Transferência De Tecidos: Uma Técnica De Apoio",
    "autor": "Maria Manuela Katzenberger Baptista Novo",
    "ficheiro": "P - 5.jpg"
  },
  {
    "numero": 47,
    "titulo": "Finefix® Fixation In Histopathological Samples With Macroscopic, Histochemistry And Immunohistochemistry Assessment: A Systematic Literature Review",
    "autor": "Natacha Monteiro et al.",
    "ficheiro": "P - 47.jpg"
  },
  {
    "numero": 56,
    "titulo": "Incidência E Mortalidade Por Cancro Do Estômago Em Países Onde Predomina A Dieta Mediterrânica",
    "autor": "Beatriz Correia et al.",
    "ficheiro": "P - 56.jpg"
  },
  {
    "numero": 48,
    "titulo": "Formalin-Free Histological Fixation And Its Impact On Organs, Cells, Proteins And Nucleic Acids - A Systematic Literasture Review",
    "autor": "Inês Lino et al.",
    "ficheiro": "P - 48.jpg"
  },
  {
    "numero": 72,
    "titulo": "Rampa: 1 Ano De Experiencia No Processamento Histológico",
    "autor": "Paula Fonseca et al.",
    "ficheiro": "P - 72.jpg"
  },
  {
    "numero": 76,
    "titulo": "Caso Clínico: Peça De Penectomia Restante Por Recidiva De Carcinoma Epidermóide.",
    "autor": "Almeida, R.; Barata, C.; Fernandes, M.; Tavares, T.",
    "ficheiro": "P - 76.jpg"
  }
]
