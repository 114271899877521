[
  {
    "numero": 2,
    "titulo": "Parasitose Intestinal Por Hymenolepis Nana – Caso Clínico",
    "autor": "Cláudia Freitas",
    "ficheiro": "P - 2.jpg"
  },
  {
    "numero": 3,
    "titulo": "Parasitose Intestinal – Um Diagnóstico Laboratorial Cada Vez Mais Raro",
    "autor": "Cláudia Freitas",
    "ficheiro": "P - 3.jpg"
  },
  {
    "numero": 71,
    "titulo": "Análise Comparativa de duas metodologias de PCR para a deteção e quantificação do EBV",
    "autor": "Raquel Costa et al.",
    "ficheiro": "P - 71.jpg"
  },
  {
    "numero": 74,
    "titulo": "Doença Pneumocócica Invasiva – Relato De Um Caso Clínico",
    "autor": "Rodrigues, A. et al.",
    "ficheiro": "P - 74.jpg"
  },
  {
    "numero": 45,
    "titulo": "Screening do agente parasitário Plasmodium spp. em pessoas dadoras da  região do Algarve e suas implicações",
    "autor": "Fábia Encarnação, Paula Rasteiro",
    "ficheiro": "P - 45.jpg"
  },
  {
    "numero": 42,
    "titulo": "Coagulopatia No Covid-19",
    "autor": "Marques, B.",
    "ficheiro": "P - 42.jpg"
  },
  {
    "numero": 61,
    "titulo": "O Diagnóstico Das Hemoglobinopatias Mais Prevalentes Em Portugal",
    "autor": "Liliana Luís, Sandrine Mendes e Ricardo Castro",
    "ficheiro": "P - 61.jpg"
  },
  {
    "numero": 50,
    "titulo": "The Role of Open Education In Improving The Accuracy Of Death Certification Practices Among Biomedical Professionals",
    "autor": "Amadeu Borges-Ferro et al.",
    "ficheiro": "P - 50.jpg"
  },
  {
    "numero": 9,
    "titulo": "Comparação Dos Subtipos Moleculares De Cancro Da Mama Em Dois Grupos Etários Diferentes",
    "autor": "Duarte, B.; Antunes, P; Coelho, T; Gonçalves, M.; Vicente, S.; Possante, A.R.; Gago-Rodrigues, I.; Plácido, R.; Furtado, J.",
    "ficheiro": "P - 9.jpg"
  }
]
