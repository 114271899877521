[
  {
    "numero": 60,
    "titulo": "A 3d Tumor-On-Chip Platform To Study Intravasation",
    "autor": "Diogo Coutinho, Carolina Morais, et al.",
    "ficheiro": "P - 60.jpg"
  },
  {
    "numero": 6,
    "titulo": "Histogel Vs Cellient: A Comparative Study",
    "autor": "Rita Fagulha; Rúben Roque; Carla Pinheiro; Vanessa Tavares; Saudade André",
    "ficheiro": "P - 6.jpg"
  },
  {
    "numero": 62,
    "titulo": "Uso De Filtros No Processamento De Citologia De Base Líquida: Importante Discriminar?",
    "autor": "Tiago Monteiro, Sílvia Fernandes, Regina Silva",
    "ficheiro": "P - 62.jpg"
  },
  {
    "numero": 67,
    "titulo": "Nódulo Hiperplásico Adenomatóide Da Tiroide: A Propósito De Um Caso Clínico",
    "autor": "Neuza Costa et al.",
    "ficheiro": "P - 67.jpg"
  },
  {
    "numero": 34,
    "titulo": "Caso Clínico - Valor Preditivo Do Cariótipo",
    "autor": "Rodolfo Serra, Raquel Costa, Andreia Sousa, Pedro Facão, Nélia Jerónimo",
    "ficheiro": "P - 34.jpg"
  },
  {
    "numero": 44,
    "titulo": "Avaliação Do Status Mutacional Dos Genes Mmr No Cancro Do Endométrio Na População Que Recorre Ao Centro Hospitalar Universitário Do Algarve",
    "autor": "Coelho, J. et al.",
    "ficheiro": "P - 44.jpg"
  },
  {
    "numero": 65,
    "titulo": "Computational Pathology: What's New",
    "autor": "Daniel Coelho et al.",
    "ficheiro": "P - 65.jpg"
  },
  {
    "numero": 26,
    "titulo": "The Importance Of The IDH Molecular Test In Glioma Diagnosis",
    "autor": "Ana Sofia Lopes, Ana Santos, Catarina Pardelha, Martinha Chorão",
    "ficheiro": "P - 26.jpg"
  }
]
